@use 'shared/styles/globals' as *;

$track-padding: 4px;
$handle-size: 13px;

.track {
  display: inline-flex;
  width: $handle-size * 2 + $track-padding * 3;
  justify-content: flex-start;
  padding: $track-padding;
  border-radius: $handle-size + $track-padding;
  background-color: var(--neutral-toggle);
  transition: all 0.2s ease-in-out;

  &::before {
    width: $handle-size;
    height: $handle-size;
    border-radius: 50%;
    background-color: var(--neutral-toggleDot);
    content: '';
    transition: all 0.2s ease-in-out;
  }
}

.input {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;

  &:checked {
    & + .track {
      background-color: var(--accent-cta);

      &::before {
        background-color: var(--neutral-toggleDot);
        transform: translate($handle-size + $track-padding);
      }
    }
  }

  &:disabled {
    &:checked {
      & + .track {
        background-color: var(--accent-cta-checkedDisabled);
      }
    }

    &:not(:checked) {
      & + .track {
        background-color: var(--neutral-toggle-disabled);

        &::before {
          background-color: var(--neutral-toggleDot-disabled);
        }
      }
    }
  }
}

.toggle {
  position: relative;
  display: inline-block;
  line-height: 0;
  vertical-align: middle;

  &--disabled {
    cursor: not-allowed;
  }

  &:not(&--disabled) {
    cursor: pointer;

    &:hover {
      .input:checked + .track {
        background-color: var(--accent-cta-checkedHover);
      }

      .input:not(:checked) + .track {
        background-color: var(--neutral-toggle-hover);
      }
    }
  }
}
