@use 'shared/styles/globals' as *;

.tech-details-header {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &-text {
    @include heading(2);

    margin: 0;
  }
}
