@use 'shared/styles/globals' as *;

.card {
  @include text(md, singleline);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 4px;
  background-color: var(--neutral-card);
  color: var(--neutral-text);
}
