$wDesktopMin: 800px;
$wMobileMin: 320px;
$wMobileMax: ($wDesktopMin - 1);

$hMin: 480px;

$contentPadding: 24px;
$sidePanelWidth: 400px;

$scrollbarWidth: 16px;
