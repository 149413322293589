@use 'shared/styles/globals' as *;
@use '../CardWrapper.module.scss' as *;

.labelContent {
  display: inline-flex;
  max-height: 40px;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  gap: 8px;

  @include card-common();

  &--fullWidth {
    display: flex;
  }
}

.labelText {
  @include card-text-disabled();
}
