@use 'shared/styles/globals' as *;

.button {
  @include text(sm, singleline);

  overflow: hidden;
  width: 100%;
  padding: 12px calc($contentPadding + 12px);
  border: none;
  background-color: transparent;
  color: var(--neutral-text);
  font-family: $ffamily;
  outline: none;
  text-align: left;
  text-overflow: ellipsis;
  transition: all 0.2s;
  white-space: nowrap;

  &.highlighted,
  &:hover,
  &:focus {
    background-color: var(--neutral-card-hover);
    cursor: pointer;
  }
}
