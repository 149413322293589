@use 'shared/styles/globals' as *;

.option {
  padding: 12px;
  cursor: pointer;
  line-height: 1rem;
  outline: none;

  &--focused,
  &--selected {
    background-color: var(--neutral-listItem-hover);
  }
}
