@use 'shared/styles/globals' as *;

.radarWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  padding-top: 120px;
  padding-bottom: 50px;
  vertical-align: middle;

  @media (max-height: 950px) {
    margin: 0;
  }
}

.svgRadar {
  display: inline-block;
  overflow: visible;
  width: 100%;
  height: 100%;
}

.downsizeRadar {
  @media (max-height: 950px) {
    margin-bottom: 0;
    transform: scale(0.85);
  }
}
