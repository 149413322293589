@use 'shared/styles/globals' as *;

.wrapper {
  @include text(md, multiline);
}

.description {
  color: var(--neutral-subtext);
}

.show_more_button {
  @include text(md, singleline, semibold);

  align-self: flex-start;
  padding: 0;
  border: none;
  margin-top: 16px;
  background: none;
  color: var(--accent-cta);
  cursor: pointer;
  font-family: $ffamily;
  outline: none;
}

.subheader {
  @include heading(2);

  margin: 0;
  margin-bottom: 16px;
}
