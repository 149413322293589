@use 'shared/styles/globals' as *;

$animationTime: 0.2s;

.header {
  all: unset;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .label {
    color: var(--neutral-text);
  }

  .caret {
    margin-right: 5px;
    transform: rotate(-180deg);
    transform-box: fill-box;
    transform-origin: center center;
    transition: transform $animationTime ease-in-out;
  }

  &--level-1 {
    .label {
      @include heading(2);
    }

    .caret {
      width: 24px;
    }
  }

  &--level-2 {
    .label {
      @include text(md, singleline);
    }

    .caret {
      width: 16px;
    }
  }

  &[aria-expanded='true'] {
    .caret {
      transform: rotate(0);
    }
  }
}

.content_wrapper {
  transition: all $animationTime linear;
}

.content {
  opacity: 1;
  transform-origin: top;
  transition: opacity $animationTime linear, transform $animationTime linear;

  &--hidden {
    opacity: 0;
    transform: scaleY(0);
  }
}
