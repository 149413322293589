@use 'shared/styles/globals' as *;

.info-modal {
  position: fixed;
  z-index: $zindex2;
  top: 80px;
  left: 420px;
  width: 224px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--neutral-cardElevated);
  color: var(--neutral-text);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
  transition-delay: 1s;

  &__header {
    @include text(sm, multiline, semibold);

    margin: 4px 0;
    text-align: center;
  }

  &__body {
    @include text(sm, multiline);

    margin: 0 8px 4px;
    text-align: center;
  }
}

:global {
  .info-modal--expanded {
    opacity: 1;
  }
}
