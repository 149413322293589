@use 'shared/styles/globals' as *;

.tooltip {
  display: flex;
  max-width: 220px;
  flex-direction: column;
  padding: 16px;
  border-radius: 4px;
  background-color: var(--neutral-cardElevated);
  box-shadow: none;
  color: var(--neutral-text);
  font-family: $ffamily;

  &__header {
    @include heading(4);
  }

  &__data-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 12px 0 0;
    gap: 8px;

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 18px;

      &__label {
        @include text(sm, singleline);

        color: var(--neutral-subtext);
      }

      &__value {
        @include text(sm, singleline, semibold);

        margin: 0;
      }
    }
  }
}
