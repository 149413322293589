@use 'shared/styles/globals' as *;

.radioGroupWrapper {
  @include text(md, multiline);

  width: 100%;
  padding: 0;
  border: none;
  margin: 0;
  color: var(--neutral-text);

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.radioGroupControls {
  display: grid;
  width: 100%;
  gap: 12px;
  grid-template-columns: 1fr 1fr 1fr;
}

.radioGroupLabelWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
  gap: 9px;
}
