@use 'shared/styles/globals' as *;

$animationTime: 0.2s;

.collaspeButton {
  $self: &;

  display: flex;
  width: 20px;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  margin: 0;
  background: none;
  background-color: var(--neutral-card);
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: background-color $animationTime ease-in-out;

  &:hover {
    background-color: var(--neutral-card-hover);
  }

  &-caret {
    width: 16px;
    color: var(--neutral-icon);
    transform: rotate(90deg);
    transition: transform $animationTime ease-in-out;

    #{$self}[aria-expanded='true'] & {
      transform: rotate(-90deg);
    }
  }
}
