@use 'shared/styles/globals' as *;

.button {
  $self: &;

  outline: none;

  &__icon {
    transform: rotate(-180deg);
    transform-box: fill-box;
    transform-origin: center center;
    transition: transform 0.2s ease-in-out;

    #{$self}[aria-expanded='true'] & {
      transform: rotate(0);
    }
  }
}
