@use 'shared/styles/globals' as *;

.toggle-button-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 4px;
  background-color: var(--neutral-card);

  &__description {
    display: flex;
    align-items: center;

    &__icon {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      margin-right: 8px;
    }

    &__label {
      @include text(md);

      color: var(--neutral-text);
    }

    &__tooltip {
      margin-left: 6px;
    }
  }
}
