@use 'shared/styles/globals' as *;

.section {
  display: flex;
  flex-direction: column;
  padding: $contentPadding;
}

.withBottomBorder {
  border-bottom: 1px solid var(--neutral-divider);
}
