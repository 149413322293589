@use 'shared/styles/globals' as *;

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 4px;
  background-color: var(--neutral-card);
}

.icon {
  color: var(--neutral-text);
}

.supLabel,
.subLabel {
  @include text(md, singleline);

  color: var(--neutral-subtext);
}

.label {
  @include text(xl, singleline, semibold);

  color: var(--neutral-text);
}
