@use 'shared/styles/globals' as *;

.resultsEmpty {
  @include text(md, multiline);

  color: var(--neutral-text);
}

.listHeader {
  @include text(md, singleline);

  display: flex;
  align-items: center;
  margin: 0 0 16px;
  color: var(--neutral-text);
}

.headerIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
