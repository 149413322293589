@use 'shared/styles/globals' as *;

.wrapper {
  position: fixed;
  bottom: 20px;
  left: 50%;
  display: flex;
  align-items: center;
  transform: translateX(-50%);

  .buttons {
    margin: 0 auto;
  }

  .buttonVisible {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  .buttonHidden {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
  }
}
