@use 'shared/styles/globals' as *;

.askFreelyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border-radius: 4px;
  background-color: var(--neutral-card);
  gap: 32px;
  text-align: center;

  .interestedInText {
    @include heading(3);
  }

  .askFreelyButton {
    @include text(md, singleline, semibold);

    width: 100%;
    padding: 12px;
    border-radius: 4px;
    background-color: var(--accent-cta);
    color: var(--neutral-buttonPrimaryText);
    cursor: pointer;
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: var(--accent-cta-hover);
    }

    &:active {
      background-color: var(--accent-cta-active);
    }
  }
}
