@use 'shared/styles/globals' as *;

.wrapper {
  display: flex;
  height: 100vh;
  padding: 0 40px;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 4px;
  margin: auto;
  background-color: var(--neutral-card);
  gap: 16px;
  text-align: center;
}

.header {
  &__text {
    @include heading(3);

    margin: 0;
    margin-top: 24px;
    color: var(--neutral-text);
  }
}

.info {
  @include text(md, multiline);

  margin: 0;
  color: var(--neutral-subtext);
}
