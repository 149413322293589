@use 'shared/styles/globals' as *;

.chip {
  $self: &;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  border-radius: 4px;
  background-color: var(--neutral-chip);

  &__label {
    @include text(sm, singleline, semibold);

    color: var(--neutral-text);
  }

  &__close-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    border: none;
    border-radius: 4px;
    margin: 0 0 0 5px;
    background: none;
    color: var(--neutral-icon);
    cursor: pointer;

    &:hover {
      background-color: var(--neutral-buttonElevated-hover);
    }

    &__icon {
      width: 9px;
      height: 9px;
      flex-shrink: 0;
    }
  }
}
