@use 'shared/styles/globals' as *;

.sidePanel {
  position: absolute;
  z-index: $zindex2;
  width: $sidePanelWidth;
  height: 100vh;
  background-color: var(--neutral-sidebar);
  color: var(--neutral-text);

  &-collapseButton {
    position: absolute;
    top: 40px;
    left: 100%;
  }
}

.content {
  overflow: auto;
  height: 100%;

  &-collapsed {
    overflow: hidden;
  }
}
