@use 'shared/styles/globals' as *;

.navlink {
  @include text(md, singleline, semibold);

  margin: 0;
  font-family: $ffamily;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &--withBackground {
    display: block;
    padding: 8px 12px;
    border-radius: 100px;
    background-color: transparent;
    color: var(--neutral-linkElipseText-inactive);

    &--active {
      background-color: var(--neutral-linkElipse-active);
      color: var(--neutral-text);
    }

    &:hover:not(&--active) {
      background-color: var(--neutral-linkElipse-hover);
      color: var(--neutral-text);
    }
  }

  &--withoutBackground {
    padding-bottom: 10px;
    border-bottom: 2px solid transparent;
    color: var(--neutral-linkBarText-inactive);

    &--active {
      border-bottom: 2px solid var(--accent-cta);
      color: var(--neutral-text);
    }

    &:hover:not(&--active) {
      border-bottom: 2px solid var(--neutral-tabNavigation-linkBarDivider-hover);
      color: var(--neutral-linkBarText-hover);
    }
  }
}
