@use 'shared/styles/globals' as *;

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style-type: none;

  &--withBackground {
    padding: 8px;
    border-radius: 48px;
    background-color: var(--neutral-card);
    gap: 8px;
  }

  &--withoutBackground {
    padding: 10px $contentPadding;
    border-bottom: 1px solid var(--neutral-divider);
  }
}
