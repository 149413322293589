@use 'shared/styles/globals' as *;

$logoHeight: 28.5px;
$logoWidth: 28.5px;
$spinnerHeight: 120px;
$spinnerWidth: 120px;

.container {
  position: relative;
  width: $spinnerWidth;
  height: $spinnerHeight;

  & .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $logoWidth;
    height: $logoHeight;
    transform: translate(-50%, -50%);
  }
}

.spinner {
  animation: rotate 2s linear infinite;

  & .track {
    stroke: var(--neutral-divider);
  }

  & .path {
    animation: dash 3s ease-in-out infinite;
    stroke: var(--accent-cta);
    stroke-linecap: round;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 377;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 300, 377;
    stroke-dashoffset: -90;
  }

  100% {
    stroke-dasharray: 300, 377;
    stroke-dashoffset: -377;
  }
}
