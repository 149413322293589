@use 'shared/styles/globals' as *;

.chip {
  @include text(sm, singleline, semibold);

  display: inline-flex;
  align-items: center;
  padding: 6px;
  border-radius: 4px;
  background-color: var(--neutral-buttonSecondary);
  color: var(--accent-cta);
  gap: 10px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: var(--neutral-buttonSecondary-hover);
  }
}

.icon {
  width: 16px;
  height: 16px;
  flex-grow: 0;
  flex-shrink: 0;
}
