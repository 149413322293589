@use '_colors.module' as *;
@use '_variables' as *;
@use 'sass:list';

@mixin pulse-animation {
  @keyframes pulse {
    0% {
      opacity: 0;
      transform: scale(1);
    }

    15% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: scale(4);
    }
  }
}

@mixin create-pulse-rings($size, $duration) {
  @include pulse-animation();

  position: relative;

  &::before,
  &::after,
  > span::before,
  > span::after {
    position: absolute;
    top: calc(50% - #{calc($size / 2)});
    left: calc(50% - #{calc($size / 2)});
    width: $size;
    height: $size;
    box-sizing: border-box;
    border: 1px solid rgba($brightBlue, 0.2);
    border-radius: 50%;
    animation: pulse $duration ease-in infinite;
    content: '';
    opacity: 0;
    pointer-events: none;
  }

  > span::before {
    animation-delay: calc(1 / 4) * $duration;
  }

  &::after {
    animation-delay: calc(1 / 2) * $duration;
  }

  > span::after {
    animation-delay: calc(3 / 4) * $duration;
  }
}

@mixin custom-scrollbar($color-variant) {
  $valid-color-variants: regular, lighter;

  &::-webkit-scrollbar {
    $scrollbarPadding: 4px;

    width: $scrollbarWidth;
    background: transparent;

    &-thumb {
      border: $scrollbarPadding solid transparent;
      border-radius: calc($scrollbarWidth - 2 * $scrollbarPadding);
      background-clip: padding-box;

      @if $color-variant == regular {
        background-color: var(--neutral-scrollbar);
      } @else if $color-variant == lighter {
        background-color: var(--neutral-scrollbarLighter);
      } @else {
        /* stylelint-disable-next-line max-line-length */
        @error 'Unknown scrollbar thumb color variant: #{$color-variant}. Expected one of following: #{$valid-color-variants}.';
      }
    }

    &-track {
      background: transparent;
    }
  }
}
