@use 'shared/styles/globals' as *;

$dotSize: 6px;

.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.radio {
  position: relative;
  width: 16px;
  height: 16px;
  border: 2px solid var(--neutral-selectionButtonBorder);
  border-radius: 50%;
  margin: 0;
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  transition: all ease-out 0.2s;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: $dotSize;
    height: $dotSize;
    border-radius: 50%;
    background-color: var(--accent-cta);
    content: ' ';
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity ease-out 0.2s, background-color ease-out 0.2s;
  }

  &:checked {
    &::after {
      opacity: 1;
    }
  }

  &:disabled {
    cursor: not-allowed;

    &:checked {
      border-color: var(--accent-cta-checkedDisabled);
      background-color: transparent;

      &::after {
        background-color: var(--accent-cta-checkedDisabled);
      }
    }

    &:not(:checked) {
      border-color: var(--neutral-selectionButtonBorder-disabled);
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &:not(:disabled) {
    &:checked {
      border-color: var(--accent-cta);

      &:hover {
        border-color: var(--accent-cta-checkedHover);
        background-color: transparent;

        &::after {
          background-color: var(--accent-cta-checkedHover);
        }
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    &:not(:checked) {
      &:hover {
        border-color: var(--neutral-selectionButtonBorder-hover);
        background-color: var(--neutral-selectionButton-hover);
      }
    }
  }
}
