@use 'shared/styles/globals' as *;

.ringTitle {
  fill: $deepBronze;
  font-size: $fs18;
  font-weight: $fwBold;
  pointer-events: none;
  user-select: none;
}

.quadrantTitle {
  fill: $pureWhite;
  font-size: $fs14;
  opacity: 0.8;
  transition: fill 0.16s ease-in;
}

.fadedQuadrantTitle,
.focusedQuadrantTitle {
  fill: $deepBronze;
  opacity: 0.8;
  transition: fill 0.16s ease-in;
}

.fadedBlip {
  opacity: 0.3;
  transition: opacity 0.5s ease-in;
}

.greyedBlip {
  fill: $deepBronze;
  transition: fill 0.5s ease-in;
}

.quarter {
  transition: opacity 0.16s ease-in;
}

.enterAnimationComplete .hoverQuadrant {
  opacity: 0.1;
}

.focusedQuadrant {
  transition: opacity 0.5s ease-in;

  .enterAnimationComplete & {
    opacity: 0.2;
  }
}
