@use 'shared/styles/globals' as *;

.wrapper {
  $self: &;

  position: fixed;
  z-index: $zindex1;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: $contentPadding;

  &__left,
  &__right {
    flex-basis: 0;
    flex-grow: 1;
  }
}
