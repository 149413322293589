@use 'shared/styles/globals' as *;

.list-box {
  @include custom-scrollbar(regular);
  @include text(md);

  overflow: auto;
  padding: 0;
  border-radius: 4px;
  margin: 0;
  color: var(--neutral-text);
  list-style: none;
}
