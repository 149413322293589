$pureWhite: #fff;
$lightGreen: #00d563;
$lavenderBlush: #f2eef1;
$nurseGrey: #cecbcd;
$brightBlue: #7aaedb;
$bistre: #1a3145;
$deepBronze: #2e4254;

$lavenderBlush05: rgba($lavenderBlush, 0.5);
$bistre07: rgba($bistre, 0.7);
$lightGreen015: rgba($lightGreen, 0.15);

// green
$green900: #003619;
$green800: #006b32;
$green700: #00a14b;
$green600: #00d664;
$green500: #00fb75;
$green400: #21ff89;
$green300: #46ff9c;
$green200: #6bffb0;
$green100: #90ffc4;
$green50: #ebfff4;

// navy
$navy900: #03080d;
$navy800: #06101a;
$navy700: #081624;
$navy650: #091a2a;
$navy600: #0b1f33;
$navy550: #15293d;
$navy500: #1d3853;
$navy400: #4c6073;
$navy300: #70879f;
$navy200: #a0afbf;
$navy100: #cfd7df;
$navy50: #f0f2f5;

// semantic
$positive: #7cc46e;
$neutral: #5cbbd6;
$neutral-v2: #c864ce;
$critical: #d6bf36;
$negative: #cd6574;

:export {
  /* stylelint-disable order/properties-order */
  // green
  green900: $green900;
  green800: $green800;
  green700: $green700;
  green600: $green600;
  green500: $green500;
  green400: $green400;
  green300: $green300;
  green200: $green200;
  green100: $green100;
  green50: $green50;

  // navy
  navy900: $navy900;
  navy800: $navy800;
  navy700: $navy700;
  navy650: $navy650;
  navy600: $navy600;
  navy550: $navy550;
  navy500: $navy500;
  navy400: $navy400;
  navy300: $navy300;
  navy200: $navy200;
  navy100: $navy100;
  navy50: $navy50;

  // semantic
  positive: $positive;
  neutral: $neutral;
  neutral_v2: $neutral-v2;
  critical: $critical;
  negative: $negative;
}
