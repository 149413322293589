@use 'shared/styles/globals' as *;

.summary {
  @include text(md, multiline);

  color: var(--neutral-subtext);
  transition: color 200ms ease-in-out;
}

.header {
  @include heading(4);

  display: flex;
  justify-content: space-between;
  margin: 0;
  color: var(--neutral-text);
  transition: color 200ms ease-in-out;
}

.articleListItem {
  list-style: none;
}

.itemLink {
  display: block;
  padding: 24px;
  border-radius: 4px;
  background-color: var(--neutral-card);
  outline: none;
  text-decoration: none;
  transition: background-color 200ms ease-in-out;

  &:hover,
  &:focus {
    background-color: var(--neutral-card-hover);
  }

  &:active {
    background-color: var(--neutral-card-active);
  }
}

.externalLinkIcon {
  width: 16px;
  height: 16px;
  flex-grow: 0;
  flex-shrink: 0;
}
