@use 'shared/styles/globals' as *;

.header {
  padding: 16px 0;
  border-top: 1px solid var(--neutral-divider);
  border-bottom: 1px solid var(--neutral-divider);
  color: var(--neutral-text);

  .row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;
  }

  .col {
    flex: 0 1 auto;

    &__flexGrow1 {
      flex-grow: 1;
    }
  }

  h3 {
    @include label(l);

    margin: 0;
  }

  h5 {
    @include label(md);

    margin: 0;
    color: var(--neutral-subtext);
  }

  .icon {
    width: 50px;
    margin-right: 16px;
    vertical-align: top;
  }
}
