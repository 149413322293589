@use 'sass:list';

$ffamily: 'AvertaStd';

$fwNormal: 400;
$fwSemiBold: 600;
$fwBold: 700;

$fs10: 0.625rem;
$fs12: 0.75rem;
$fs13: 0.8125rem;
$fs14: 0.875rem;
$fs16: 1rem;
$fs18: 1.125rem;
$fs20: 1.25rem;
$fs22: 1.375rem;
$fs24: 1.5rem;
$fs28: 1.75rem;
$fs32: 2rem;
$fs40: 2.5rem;
$fs44: 2.75rem;
$fs60: 3.75rem;

@mixin heading($level) {
  @if $level == 1 {
    font-size: $fs32;
    font-weight: $fwSemiBold;
    line-height: $fs44;
  } @else if $level == 2 {
    font-size: $fs22;
    font-weight: $fwSemiBold;
    line-height: $fs28;
  } @else if $level == 3 {
    font-size: $fs18;
    font-weight: $fwSemiBold;
    line-height: $fs24;
  } @else if $level == 4 {
    font-size: $fs16;
    font-weight: $fwSemiBold;
    line-height: $fs22;
  } @else {
    @error 'Unknown heading level: #{$level}.';
  }
}

@mixin label($size) {
  @if $size == l {
    font-size: $fs16;
    font-weight: $fwSemiBold;
  } @else if $size == md {
    font-size: $fs14;
    font-weight: $fwNormal;
  } @else {
    @error 'Unknown label size: #{$size}.';
  }
}

@mixin text($size, $line-height: singleline, $weight: regular) {
  $valid-line-heights: singleline, multiline;
  $valid-weights: regular, semibold;
  $valid-sizes: sm, md, xl;

  @if not list.index($valid-line-heights, $line-height) {
    @error 'Unknown text line height: #{$line-height}. Expected one of following: #{$valid-line-heights}.';
  }

  @if $size == xl {
    font-size: $fs24;
    line-height: if($line-height == singleline, $fs24, $fs40);
  } @else if $size == md {
    font-size: $fs16;
    line-height: if($line-height == singleline, $fs16, $fs24);
  } @else if $size == sm {
    font-size: $fs14;
    line-height: if($line-height == singleline, $fs14, $fs24);
  } @else {
    @error 'Unknown text size: #{$size}. Expected one of following: #{$valid-sizes}';
  }

  @if $weight == regular {
    font-weight: $fwNormal;
  } @else if $weight == semibold {
    font-weight: $fwSemiBold;
  } @else {
    @error 'Unknown text weight: #{$weight}. Expected one of following: #{$valid-weights}';
  }
}

:export {
  ffamily: $ffamily;
  fs10: $fs10;
  fs12: $fs12;
  fs13: $fs13;
  fs14: $fs14;
  fs16: $fs16;
  fs18: $fs18;
  fs20: $fs20;
  fs22: $fs22;
  fs24: $fs24;
  fs28: $fs28;
  fs32: $fs32;
  fs40: $fs40;
  fs44: $fs44;
  fs60: $fs60;
  fwBold: $fwBold;
  fwNormal: $fwNormal;
  fwSemiBold: $fwSemiBold;
}
