@use 'shared/styles/globals' as *;

.wrapper {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: $wMobileMax) {
    button {
      height: 100%;
      border-radius: 0 3px 0 0;
      margin: 0;
    }
  }
}

.loginLabel {
  @include text(md);

  margin-left: 15px;
  color: var(--neutral-text);

  @media (max-width: $wMobileMax) {
    display: none;
  }
}

.loginButtonLabel {
  font-weight: $fwBold;
}
