@use 'shared/styles/globals' as *;

.btn {
  @include text(md, singleline, semibold);

  padding: 0;
  border: none;
  background: none;
  color: var(--neutral-text);
  cursor: pointer;
  font-family: $ffamily;
  outline: none;

  &__iconBack {
    display: inline-block;
    margin-right: 8px;

    path {
      fill: var(--neutral-text);
    }
  }
}
