@use 'shared/styles/globals' as *;

@font-face {
  font-display: block;
  font-family: $ffamily;
  font-style: $fwNormal;
  src: url('./assets/fonts/AvertaStd-Regular.otf') format('opentype');
}

@font-face {
  font-display: block;
  font-family: $ffamily;
  font-weight: $fwBold;
  src: url('./assets/fonts/AvertaStd-Bold.otf') format('opentype');
}

:root {
  // accent
  --accent-cta: #{$green600};
  --accent-cta-hover: #{$green700};
  --accent-cta-active: #{$green800};
  --accent-cta-checkedDisabled: #{$green800};
  --accent-ctaText: #{$green600};
  --accent-cta-checkedHover: #{$green700};

  // neutral
  --neutral-buttonSecondary: #{$navy550};
  --neutral-buttonSecondary-hover: #{$navy500};
  --neutral-buttonSecondary-active: #{$navy550};
  --neutral-buttonTertiary-hover: #{$navy500};
  --neutral-buttonElevated-hover: #{$navy400};
  --neutral-buttonPrimaryText: #{$navy800};
  --neutral-buttonPrimary-disabled: #{$navy600};
  --neutral-buttonPrimaryText-disabled: #{$navy300};
  --neutral-buttonSecondary-disabled: #{$navy600};
  --neutral-buttonSecondaryText-disabled: #{$navy300};
  --neutral-buttonTertiaryText-disabled: #{$navy300};
  --neutral-card: #{$navy600};
  --neutral-card-hover: #{$navy550};
  --neutral-card-active: #{$navy600};
  --neutral-cardElevated: #{$navy500};
  --neutral-card-disabled: #{$navy650};
  --neutral-cardContent-disabled: #{$navy400};
  --neutral-dropdown: #{$navy600};
  --neutral-dropdownBorder: #{$navy500};
  --neutral-selectButtonCard-disabled: #{$navy500};
  --neutral-selectButtonCardContent-disabled: #{$navy200};
  --neutral-toggle: #{$navy100};
  --neutral-toggle-hover: #{$navy200};
  --neutral-toggle-disabled: #{$navy300};
  --neutral-toggleDot: #{$navy800};
  --neutral-toggleDot-disabled: #{$navy500};
  --neutral-select: #{$navy600};
  --neutral-select-hover: #{$navy550};
  --neutral-select-active: #{$navy600};
  --neutral-select-disabled: #{$navy500};
  --neutral-selectText-content: #{$navy200};
  --neutral-selectionButtonBorder: #{$navy200};
  --neutral-selectionButtonBorder-hover: #{$navy100};
  --neutral-selectionButtonBorder-disabled: #{$navy400};
  --neutral-selectionButton-hover: #{$navy500};
  --neutral-selectionButton-disabled: #{$navy500};
  --neutral-text: #{$navy50};
  --neutral-subtext: #{$navy200};
  --neutral-placeholderText: #{$navy300};
  --neutral-inputField: #{$navy600};
  --neutral-inputField-disabled: #{$navy650};
  --neutral-inputFieldBorder: #{$navy400};
  --neutral-inputFieldContent-disabled: #{$navy400};
  --neutral-linkElipse-hover: #{$navy550};
  --neutral-linkElipse-active: #{$navy500};
  --neutral-linkElipseText-inactive: #{$navy100};
  --neutral-linkBarText-inactive: #{$navy200};
  --neutral-linkBarText-hover: #{$navy50};
  --neutral-tabNavigation-linkBarDivider-hover: #{$navy400};
  --neutral-chip: #{$navy500};
  --neutral-icon: #{$navy50};
  --neutral-rangeSliderLine: #{$navy500};
  --neutral-listItem-hover: #{$navy500};
  --neutral-skeleton: #{$navy600};
  --neutral-skeletonAnimation: #{$navy550};
  --neutral-sidebar: #{$navy700};
  --neutral-prompt: #{$navy700};
  --neutral-modal: #{$navy700};
  --neutral-background: #{$navy800};
  --neutral-divider: #{$navy550};
  --neutral-scrollbar: #{$navy550};
  --neutral-scrollbarLighter: #{$navy500};
  --neutral-nodeLink: #{$navy400};
  --neutral-nodeLink-inactive: #{$navy500};

  // semantic
  --semantic-success: #{$positive};
  --semantic-neutral: #{$neutral};
  --semantic-neutral2: #{$neutral-v2};
  --semantic-critical: #{$critical};
  --semantic-danger: #{$negative};
}

body {
  overflow: hidden;
  min-width: $wMobileMin;
  padding: 0;
  margin: 0;
  background-color: var(--neutral-background);
  font-family: $ffamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: $fwNormal;
}

* {
  box-sizing: border-box;
}
