@use 'shared/styles/globals' as *;

.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.rightSide {
  flex-grow: 1;
}
