@use 'shared/styles/globals' as *;

.tooltip {
  @include text(sm, multiline);

  z-index: $zindex2;
  max-width: 400px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--neutral-cardElevated);
  color: var(--neutral-text);
}
