@use 'shared/styles/globals' as *;

.diff {
  &--increase {
    color: var(--semantic-success);
  }

  &--decrease {
    color: var(--semantic-danger);
  }
}
