@use 'shared/styles/globals' as *;

.labelText {
  margin-right: 4px;
}

.labelIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
