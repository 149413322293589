@use 'shared/styles/globals' as *;

.button {
  $self: &;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: $ffamily;
  gap: 8px;
  transition: all 0.2s ease-in-out;

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  &--full-width {
    width: 100%;
  }

  &--sm {
    @include text(sm, singleline, semibold);
  }

  &--md {
    @include text(md, singleline, semibold);
  }

  &--primary {
    background-color: var(--accent-cta);
    color: var(--neutral-buttonPrimaryText);

    &:disabled {
      background-color: var(--neutral-buttonPrimary-disabled);
      color: var(--neutral-buttonPrimaryText-disabled);
    }

    &:not(:disabled) {
      &:hover {
        background-color: var(--accent-cta-hover);
      }

      &:active {
        background-color: var(--accent-cta-active);
      }
    }

    &#{$self}--sm {
      padding: 6px;
    }

    &#{$self}--md {
      padding: 12px;
    }
  }

  &--secondary {
    background-color: var(--neutral-buttonSecondary);
    color: var(--accent-cta);

    &:disabled {
      background-color: var(--neutral-buttonSecondary-disabled);
      color: var(--neutral-buttonSecondaryText-disabled);
    }

    &:not(:disabled) {
      &:hover {
        background-color: var(--neutral-buttonSecondary-hover);
      }

      &:active {
        background-color: var(--neutral-buttonSecondary-active);
      }
    }

    &#{$self}--sm {
      padding: 6px;
    }

    &#{$self}--md {
      padding: 12px;
    }
  }

  &--tertiary {
    background-color: transparent;
    color: var(--neutral-text);

    &:disabled {
      color: var(--neutral-buttonTertiaryText-disabled);
    }

    &:not(:disabled) {
      &:hover {
        background-color: var(--neutral-buttonTertiary-hover);
      }

      &:active {
        background-color: transparent;
      }
    }

    &#{$self}--sm,
    &#{$self}--md {
      padding: 6px;
    }
  }

  &__icon {
    display: flex;
    width: 1em;
    height: 1em;
  }
}
