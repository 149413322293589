@use 'shared/styles/globals' as *;

.container {
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  box-sizing: border-box;
  flex-direction: column;
  align-items: normal;
  justify-content: center;
  padding: $contentPadding;
  background-image: url('~assets/png/welcome-screen-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: $wMobileMax) {
    height: auto;
    min-height: 100vh;
  }
}

.leftSideLogo {
  display: flex;
  min-height: 40px;
  align-items: center;
  justify-content: normal;
  margin-bottom: auto;
  color: $lavenderBlush;
  font-size: $fs20;

  svg {
    margin-top: 2px;
    vertical-align: top;
  }
}

.logo {
  &__title {
    margin-bottom: 2px;
    margin-left: 15px;

    @media (max-width: $wMobileMax) {
      display: none;
    }
  }

  &__version {
    padding: 3px 6px;
    border-radius: 12px;
    margin-bottom: 2px;
    margin-left: 10px;
    background-color: $lightGreen015;
    color: $lightGreen;
    font-size: $fs10;
  }
}

.welcomeContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: normal;
  padding: 48px 0;
  margin-bottom: auto;

  &__title {
    width: 700px;
    margin-bottom: 20px;
    color: $pureWhite;
    font-size: $fs60;
    font-weight: $fwBold;
    line-height: 1.33;
    text-align: center;

    @media (max-width: $wMobileMax) {
      $calculatedFontSize: calc(0.074 * 100vw + 0.07rem);

      width: auto;
      font-size: $calculatedFontSize;
    }

    @media (max-width: 420px) {
      font-size: $fs32;
    }
  }

  &__description {
    max-width: 825px;
    margin-bottom: 35px;
    color: $lavenderBlush05;
    line-height: 1.69;
    text-align: center;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: normal;
  }

  &__explore {
    margin-right: 15px;
    color: $nurseGrey;
    font-size: $fs18;
    font-weight: $fwBold;
  }

  &__playButton {
    @include create-pulse-rings(42px, 6s);

    display: inline-flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50px;
    background-color: $bistre;
    cursor: pointer;

    &:hover {
      background-color: $bistre07;
    }
  }
}
