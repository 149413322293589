@use 'shared/styles/globals' as *;

.toast {
  display: flex;
  width: 100%;
  max-width: 752px;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 40px;
  border-radius: 4px;
  margin: auto;
  background-color: var(--neutral-cardElevated);
  gap: 24px;
}

.leftSide {
  @include text(md, singleline, regular);

  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--neutral-text);
  font-family: $ffamily;
  gap: 12px;
}

.closeButton {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 0;
  padding: 4px;
  border: none;
  background: none;
  background-color: transparent;
  color: var(--neutral-text);
  cursor: pointer;
  margin-block: auto;
  transition: background-color ease-out 0.1s;

  &:hover {
    border-radius: 4px;
    background-color: var(--neutral-buttonElevated-hover);
  }
}

.severityWrapper {
  display: flex;
}

.severityIcon {
  width: 24px;
  height: 24px;
}

.closeIcon {
  width: 16px;
  height: 16px;
}
