@mixin card-common {
  border-radius: 4px;
  background-color: var(--neutral-card);
  color: var(--neutral-text);
  cursor: pointer;
  text-transform: capitalize;
  transition: background ease-out 0.2s;

  &--disabled {
    background-color: var(--neutral-card-disabled);
    cursor: not-allowed;
  }

  &:not(&--disabled) {
    &:hover {
      background-color: var(--neutral-card-hover);
    }
  }
}

@mixin card-text-disabled {
  &--disabled {
    color: var(--neutral-cardContent-disabled);
  }
}
