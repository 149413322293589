@use 'shared/styles/globals' as *;

.container {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}

.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
