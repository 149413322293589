@use 'shared/styles/globals' as *;

.input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__native {
    @include text(md);

    width: 100%;
    height: 16px;
    box-sizing: content-box;
    padding: 11px;
    border: 1px solid var(--neutral-inputField);
    border-radius: 4px;
    margin: 0;
    background-color: var(--neutral-inputField);
    color: var(--neutral-text);
    font-family: $ffamily;
    outline: none;
    transition: all 0.1s linear;

    &::placeholder {
      color: var(--neutral-placeholderText);
    }

    &:disabled {
      background-color: var(--neutral-inputField-disabled);
      color: var(--neutral-inputFieldContent-disabled);
      cursor: not-allowed;
    }

    &:not(:disabled) {
      &:hover {
        border-color: var(--neutral-inputFieldBorder);
      }

      &:active,
      &:focus {
        border-color: $green600;
      }

      &[aria-invalid='true'] {
        &,
        &:focus,
        &:hover {
          border-color: var(--semantic-danger);
        }
      }
    }

    &--left-padding {
      padding-left: calc(12px * 2 + 1rem);
    }

    &--right-padding {
      padding-right: calc(12px * 2 + 1rem);
    }
  }

  &__addition {
    position: absolute;
    top: 50%;
    display: flex;
    transform: translateY(-50%);

    &--left {
      left: 12px;
    }

    &--right {
      right: 12px;
    }
  }
}
