@use 'shared/styles/globals' as *;

.navigation {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  padding: 6px 0;
}

.copyButton {
  @include text(sm, singleline);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  margin-left: 15px;
  background-color: transparent;
  color: var(--accent-cta);
  cursor: pointer;
  outline: none;
  text-decoration: underline;

  .copyButtonLabel {
    margin-right: 6px;
  }
}
