@use 'shared/styles/globals' as *;

.noResourcesText {
  @include text(md, multiline);

  color: var(--neutral-text);
}

.link {
  @include text(md, multiline, semibold);

  color: var(--accent-cta);
  text-decoration: none;
}
