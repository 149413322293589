@use 'shared/styles/globals' as *;

.input-addition {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  margin: 0;
  background: 0;
  color: var(--neutral-icon);

  &--button {
    font-size: inherit;

    &:disabled {
      cursor: not-allowed;
    }

    &:not(:disabled) {
      cursor: pointer;
    }
  }

  svg {
    width: 1em;
    height: 1em;
    flex-shrink: 0;
  }
}
