@use 'shared/styles/globals' as *;
@use '../CardWrapper.module.scss' as *;

.label {
  display: flex;
  width: 100%;
  min-height: 112px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 10px;

  @include card-common();
}

.title {
  @include text(xl, singleline, semibold);
  @include card-text-disabled();

  margin: 8px 0 16px 0;
}

.subLabel {
  color: var(--neutral-subtext);

  @include card-text-disabled();
}
