@use 'shared/styles/globals' as *;

.list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  margin-right: -$contentPadding;
  margin-left: -$contentPadding;
  list-style: none;
  transition: height 0.5s ease-out;
}
